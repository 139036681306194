import React from "react"
import PropTypes from "prop-types"

import ArticlesComponent from "../components/articles"
import Layout from "../components/layout"

const i18n = {
  noContent: "There is no content."
}

export const CategoryContainer = ({ langkey, i18n, articles, name }) => {
  return (
    <div className="uk-container uk-container-large">
      <h1 className="uk-text-center uk-text-left@l">{name}</h1>
      {articles.length === 0 ? (
        <h3 className="container uk-text-danger">{i18n.noContent}</h3>
      ) : (
        <ArticlesComponent langKey={langkey} articles={articles} />
      )}
    </div>
  )
}

const Category = ({ location, pageContext }) => {
  const { articles, name } = pageContext
  const langKey = 'en'
  return (
    <Layout location={location}>
      <CategoryContainer langkey={langKey} i18n={i18n} articles={articles} name={name} />
    </Layout>
  )
}

export default Category

Category.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
}
